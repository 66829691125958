import React, { ReactChild } from 'react'
import styled from 'styled-components'

interface IGrid {
  children: ReactChild | ReactChild[]
}

export const Grid = ({ children }: IGrid) => {
  return <StyledGrid>{children}</StyledGrid>
}

export const StyledGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1360px) 1fr;
  grid-gap: 0rem;
  justify-self: center;
  justify-items: center;
  padding: 0rem;

  @media (max-width: 888px) {
    grid-template-columns: 1fr;
  }
`
