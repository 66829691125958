import React from 'react'
import styled from 'styled-components'
import { Navigation } from './navigation'

interface IFooter {
  isContact?: boolean
}

const Footer = ({ isContact }: IFooter) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <StyledFooter>
        <Navigation />
        <div>
          <StyledContactInfo>
            <a
              href="https://www.google.ca/maps/place/Vancouver,+BC/@49.2095422,-122.8441003,10.75z/data=!4m5!3m4!1s0x548673f143a94fb3:0xbb9196ea9b81f38b!8m2!3d49.2827291!4d-123.1207375"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>Location</h2>
              <p>Greater Vancouver</p>
            </a>
            <a href="mailto:reliableconstructionltd0@gmail.com">
              <h2>Email</h2>
              <p>reliableconstructionltd0@gmail.com</p>
            </a>
            <a href="tel:604-376-1224">
              <h2>Phone</h2>
              <p>+1(604)376-1224</p>
            </a>
            <button onClick={scrollToTop}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ffffff"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-up"
              >
                <line x1="12" y1="19" x2="12" y2="5"></line>
                <polyline points="5 12 12 5 19 12"></polyline>
              </svg>
            </button>
          </StyledContactInfo>
        </div>
      </StyledFooter>
      {/* <StyledLegal>
        <small>Website Development by malloy.dev</small>
      </StyledLegal> */}
    </>
  )
}

export default Footer

const StyledFooter = styled.footer`
  display: flex;
  padding: 1rem;
  background-color: var(--text);
  width: 100%;
  max-width: 1920px;
  justify-content: center;
  padding: 3rem;

  @media (max-width: 588px) {
    flex-direction: column;
  }
`

const StyledContactInfo = styled.div`
  padding-top: 3rem;
  flex: 1;

  a {
    h2,
    p {
      padding-left: 0;
      padding-top: 0;
      color: var(--primary);
      word-break: break-all;
    }

    p {
      color: var(--link-primary);
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    cursor: pointer;
  }
`
