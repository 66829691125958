import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { useStore } from '../Context'
import { Logo } from './logo'

const Header = () => {
  const { setDrawerPosition, setNavCount, navCount } = useStore()

  const handleClick = () => {
    setNavCount(navCount + 1)
    setDrawerPosition({ isOpen: true, sliding: true, dir: 'RIGHT' })
    setTimeout(() => {
      setDrawerPosition({ isOpen: true, sliding: false, dir: 'RIGHT' })
    }, 50)
  }

  return (
    <StyledHeader>
      <StyledLogo to="/">
        <Logo />
      </StyledLogo>
      <StyledNavigation>
        <Link to="/" activeStyle={{ textDecoration: 'underline' }}>
          Home
        </Link>
        <Link to="/about/" activeStyle={{ textDecoration: 'underline' }}>
          About
        </Link>
        <Link to="/services/" activeStyle={{ textDecoration: 'underline' }}>
          Services
        </Link>
        <Link to="/contact/" className="call-to-action">
          Contact
        </Link>
      </StyledNavigation>
      <StyledMobileButton onClick={handleClick} aria-label="Toggle Mobile Navigation Drawer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
          <text x="50" y="90" textAnchor="middle">
            &lt;M&S Reliable Construction Logo - Navigate Home&gt;
          </text>
        </svg>
      </StyledMobileButton>
    </StyledHeader>
  )
}

export default Header

const StyledMobileButton = styled.button`
  display: fixed;
  top: 0;
  right: 0;
  padding: 2rem;
  background-color: var(--primary);
  border: none;

  @media (min-width: 775px) {
    display: none;
  }
`

const StyledHeader = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1920px;
  z-index: 10;
  background-color: white;
  background: rgba(1, 1, 1, 0.8);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
`

const StyledNavigation = styled.nav`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  max-width: 1920px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex: 2;

  @media (max-width: 775px) {
    grid-template-columns: 1fr;
    display: none;
    pointer-events: none;
  }

  a {
    padding: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.call-to-action {
      color: var(--primary);
      background-color: var(--brand);
      border-radius: 8px;
    }
  }

  button {
    @media (min-width: 775px) {
      display: none;
    }

    justify-self: center;
    grid-row: 1 / 3;
    align-self: center;
    padding: 1rem;
    margin: 1rem;
    grid-column: 4 / 4;
    grid-row: 1 / span 2;
    justify-self: center;
    background-color: transparent;
    border: none;

    @media (min-width: 775px) {
      display: none;
    }
  }
`

const StyledLogo = styled(Link)`
  padding: 0.5rem;
  font-family: 'Source Sans Pro Black';
  font-weight: 900;
  font-size: 3rem !important;
  flex: 1;
  color: var(--brand);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
`
