import React, { ReactChild } from 'react'
import styled from 'styled-components'
import { GlobalStyles } from './GlobalStyles'
import Footer from './components/footer'
import {
  bqFontSizes,
  h1FontSizes,
  h2FontSizes,
  h3FontSizes,
  h4FontSizes,
  h5FontSizes,
  pFontSizes,
  smallFontSizes,
} from './createMediaQuery'
import Header from './components/header'
import { ManagedUIContext } from './Context'
import { Grid } from './Grid'
import { Drawer } from './components/drawer/drawer'
import Container from './Container'
import './fonts/font.css'
import { Notifications } from './components/notifications'

const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  ol {
    color: var(--text);
  }

  small {
  }

  div {
    ${pFontSizes()}
  }

  h1 {
    ${h1FontSizes()}
    font-size: clamp(1.009rem, 13.5vw, 12.873rem);
    line-height: clamp(1.209rem, 10.5vw, 9.873rem);
    letter-spacing: 0.31vw;
  }

  h2 {
    ${h2FontSizes()}
    letter-spacing: 0.11vw;
  }

  h3 {
    ${h3FontSizes()}
    letter-spacing: 0.11vw;
  }

  h4 {
    ${h4FontSizes()}
  }

  h5 {
    ${h5FontSizes()}
  }

  small {
    ${smallFontSizes()}
    font-weight: 800;
  }

  @media (max-width: 775px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0.5rem;
    }
  }

  p,
  ol,
  li,
  ul {
    ${pFontSizes()}
    font-weight: 800;
    line-height: 2.5rem;

    @media (max-width: 576px) {
      padding: 0.1rem;
    }
  }

  p {
    @media (max-width: 576px) {
      padding: 1rem;
    }
  }

  li {
    list-style: disc inside;
    text-indent: 0px;
    padding: 0 1rem;

    p {
      text-indent: 0px;
    }
  }

  blockquote {
    /* border-left: 0.5rem solid #663399; */
    border-radius: 0.2rem;
    background-color: hsla(0, 0%, 100%, 0.6);
    padding: var(--space);
    ${bqFontSizes()}
    line-height: 2.5rem;
    border-radius: 1rem;
    font-weight: 800;

    p {
      padding: 0 var(--space);
    }

    @media (max-width: 576px) {
      margin: var(--space);
    }
  }

  a {
    ${pFontSizes()}
    font-weight: 800;
    color: var(--link-primary);
  }

  mark {
    ${pFontSizes()}
    font-weight: 800;
    line-height: 2.5rem;
    background-color: var(--link-primary);
    color: var(--primary);
    padding: 0 0.5rem;
  }
`

export const ExtendStyledLayout = styled(StyledLayout)({
  ...h1FontSizes(),
})

interface ILayout {
  children: ReactChild | ReactChild[]
  isContact?: boolean
}

const Layout = ({ children, isContact }: ILayout) => {
  return (
    <ManagedUIContext>
      <ExtendStyledLayout>
        <Notifications />
        <Header />
        <Container>
          <GlobalStyles />
          <Drawer />
          <Grid>{children}</Grid>
        </Container>
        <Footer isContact={isContact} />
      </ExtendStyledLayout>
    </ManagedUIContext>
  )
}

export default Layout
