import React, { ReactChild } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.section`
  max-width: 1920px;
  width: 100%;
`

interface IContainerProps {
  children: ReactChild | ReactChild[]
}

const Container = ({ children }: IContainerProps) => {
  return <StyledContainer>{children}</StyledContainer>
}

export default Container
