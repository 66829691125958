import React from 'react'
import styled from 'styled-components'

const StyledLogo = styled.svg`
  fill: white;
  max-height: 100px;
  width: 100%;
`

export const Logo = () => {
  return (
    <StyledLogo version="1.1" x="0px" y="0px" viewBox="0 0 1847.8 534.8">
      <g id="Layer_3">
        <g id="XMLID_76_">
          <g id="XMLID_4_">
            <path id="XMLID_8_" d="M20.4,514.8L20.4,514.8z" />
            <path id="XMLID_7_" className="st0" d="M77,514.8" />
            <path id="XMLID_6_" className="st0" d="M1768,514.8" />
            <path id="XMLID_9_" d="M1824.6,514.8L1824.6,514.8z" />
            <polygon id="XMLID_85_" points="20.4,514.8 216.7,318.5 216.7,375.1 77,514.8" fill="var(--brand)" />
            <polyline
              id="XMLID_3_"
              fill="var(--brand)"
              points="1523.4,213.6 1466.8,213.6 1316.4,63.2 552.6,63.2 703,213.6 646.4,213.6 512.3,79.5 
				378.1,213.6 321.6,213.6 512.7,23.4 512.7,23.4 512.7,23.4 512.7,23.4 512.7,23.4 1332.6,23.2"
            />
            <polygon id="XMLID_87_" points="1824.6,514.8 1768,514.8 1642,388.8 1642,332.3" fill="var(--brand)" />
          </g>
          <g id="XMLID_148_">
            <path
              id="XMLID_149_"
              d="M432.8,232.4l1.2,283.1l-43.5,0.2l-0.8-194.2l-39.8,194.3l-30.4,0.1L278,322l0.8,194.2l-42.7,0.2
				l-1.2-283.1l61.6-0.3L335,413.6l35.4-181L432.8,232.4z"
            />
            <path
              id="XMLID_151_"
              d="M605.5,514.8L593.1,495c-15.5,16.2-33.6,24-54.1,24.1c-35.7,0.2-64.1-22.8-64.3-70.9
				c-0.1-33.6,13.7-54.7,37.8-79.8l-6.2-9.7c-10.7-17.3-23.2-39.9-23.3-66.6c-0.2-36.8,24.8-64,63.3-64.2
				c36.5-0.2,61.3,23.6,61.4,60.8c0.1,30.3-9.2,44.9-44.4,83.1l27.7,42c3.2-11.3,6.1-24.7,9.3-40.5l43.5-0.2
				c-7.2,33.6-15.3,60.7-25.5,82.2l39.2,59.3L605.5,514.8z M572.9,463.2l-38-59.3c-11.4,14.2-16.7,25.2-16.6,42.1
				c0.1,24.7,12.9,32.7,28.9,32.6C556.9,478.6,565.1,474.9,572.9,463.2z M524.9,291.9c0.1,12.5,4.6,27.9,17,47.3
				c17.6-17.5,26.1-29.6,26.1-47.4c-0.1-16.6-7.5-25.5-21-25.4C533,266.4,524.9,276.5,524.9,291.9z"
            />
            <path
              id="XMLID_155_"
              d="M684,449l0-10.1l41.8-4.6l0,10.5c0.1,20.6,10.8,33.1,28,33.1c17.2-0.1,27.9-10.6,27.8-27.2
				c-0.1-17.8-7.5-30.7-40.1-60.9c-43.3-39.9-55.7-61.7-55.8-92.8c-0.2-42.1,24.3-69.7,65.8-69.9c41.4-0.2,66.2,27.2,66.4,69.7
				l0,7.3l-41.8,4.6l0-8.1c-0.1-21.8-8.8-33.1-23.9-33.1c-15.2,0.1-23.8,9.4-23.7,27.2s9.2,30.7,40.1,60.9
				c42,41.1,55.7,61.7,55.8,92.8c0.2,40.4-28.4,69.7-69.9,69.9C713,518.4,684.2,489.4,684,449z"
            />
          </g>
          <g id="XMLID_127_">
            <path
              id="XMLID_128_"
              d="M926.7,390L907,323.8h-14.1V390h-27.1V229.7h42.4c25.8,0,42.4,15.1,42.4,38.2v17.6
				c0,14.9-6.9,26.3-18.7,32.7l22.8,71.7H926.7z M892.9,300.9H907c10.5,0,16.6-5.5,16.6-14.9v-18.5c0-9.4-6.1-14.9-16.6-14.9h-14.1
				V300.9z"
            />
            <path id="XMLID_131_" d="M977,229.7h77v22.9h-49.9v44.7h39.6v22.9h-39.6v46.9h49.9V390h-77V229.7z" />
            <path id="XMLID_133_" d="M1076.7,229.7h27.1v137.4h48.3V390h-75.4V229.7z" />
            <path id="XMLID_135_" d="M1173.1,229.7h27.1V390h-27.1V229.7z" />
            <path
              id="XMLID_137_"
              d="M1283.3,354.5h-29.1l-5.9,35.5h-27.1l29.7-160.3h36.3l29.7,160.3h-27.6L1283.3,354.5z M1279.7,331.1
				l-11-67.1l-11,67.1H1279.7z"
            />
            <path
              id="XMLID_140_"
              d="M1425.4,338.7v13.1c0,23.1-16.6,38.2-42.4,38.2h-45.3V229.7h43c25.8,0,42.4,15.1,42.4,38.2v8.5
				c0,14.4-6.4,24.7-18.2,30.7C1418,312.6,1425.4,323.3,1425.4,338.7z M1364.9,252.6v43.5h12c13,0,19.2-5.5,19.2-17.2v-11.4
				c0-9.4-6.1-14.9-16.6-14.9H1364.9z M1398.3,336.2c0-11.7-6.1-17.2-19.2-17.2h-14.3v48.1h16.9c10.5,0,16.6-5.5,16.6-14.9V336.2z"
            />
            <path id="XMLID_144_" d="M1450,229.7h27.1v137.4h48.3V390H1450V229.7z" />
            <path id="XMLID_146_" d="M1546.4,229.7h77v22.9h-49.9v44.7h39.6v22.9h-39.6v46.9h49.9V390h-77V229.7z" />
          </g>
          <g id="XMLID_90_">
            <path
              id="XMLID_91_"
              d="M871.8,493.4v-53.1c0-13.4,8.2-22.7,20.3-22.7s20.3,9.3,20.3,22.7v11.2h-12.7v-11.2c0-5.6-3.1-9-7.4-9
				s-7.4,3.4-7.4,9v53.1c0,5.6,3.1,9,7.4,9s7.4-3.4,7.4-9v-13.7h12.7v13.7c0,13.4-8.2,22.7-20.3,22.7S871.8,506.9,871.8,493.4z"
            />
            <path
              id="XMLID_93_"
              d="M923.5,493v-52.3c0-13.8,8.3-23.1,20.8-23.1c12.4,0,20.7,9.3,20.7,23.1V493c0,13.8-8.3,23.1-20.7,23.1
				C931.8,516.2,923.5,506.9,923.5,493z M952.1,493v-52.3c0-6-3.1-9.4-7.8-9.4c-4.8,0-7.8,3.4-7.8,9.4V493c0,6,3.1,9.4,7.8,9.4
				C949,502.5,952.1,499.1,952.1,493z"
            />
            <path id="XMLID_96_" d="M978.1,419h14.3l16.5,64.3V419h12.7v95.8h-13.7l-17.1-61.3v61.3h-12.7V419z" />
            <path
              id="XMLID_98_"
              d="M1032.8,492.6v-3.4l12.5-1.5v3.6c0,7,3.2,11.2,8.3,11.2c5.1,0,8.3-3.6,8.3-9.2c0-6-2.2-10.4-11.8-20.7
				c-12.8-13.6-16.5-20.9-16.5-31.5c0-14.2,7.3-23.5,19.7-23.5c12.3,0,19.7,9.3,19.7,23.7v2.5l-12.4,1.5v-2.7
				c0-7.4-2.6-11.2-7.1-11.2c-4.5,0-7.1,3.1-7.1,9.2c0,6,2.7,10.4,11.8,20.7c12.5,14,16.5,20.9,16.5,31.5c0,13.7-8.5,23.5-20.9,23.5
				C1041.3,516.2,1032.8,506.3,1032.8,492.6z"
            />
            <path id="XMLID_100_" d="M1081,419h41.3v13.7h-14.2v82.1h-12.9v-82.1H1081V419z" />
            <path
              id="XMLID_102_"
              d="M1161.3,514.8l-9.4-39.6h-6.7v39.6h-12.9V419h20.3c12.3,0,20.3,9,20.3,22.9v10.5
				c0,8.9-3.3,15.7-8.9,19.6l10.9,42.8H1161.3z M1145.2,461.6h6.7c5,0,7.9-3.3,7.9-8.9v-11.1c0-5.6-2.9-8.9-7.9-8.9h-6.7V461.6z"
            />
            <path
              id="XMLID_105_"
              d="M1184.9,493.7V419h12.9v74.7c0,5.6,3.1,8.8,7.2,8.8c4.1,0,7.2-3.1,7.2-8.8V419h12.7v74.7
				c0,13.4-8.1,22.4-19.9,22.4C1193.2,516.2,1184.9,507.1,1184.9,493.7z"
            />
            <path
              id="XMLID_107_"
              d="M1237.5,493.4v-53.1c0-13.4,8.2-22.7,20.3-22.7c12.1,0,20.3,9.3,20.3,22.7v11.2h-12.7v-11.2
				c0-5.6-3.1-9-7.4-9c-4.4,0-7.4,3.4-7.4,9v53.1c0,5.6,3.1,9,7.4,9c4.4,0,7.4-3.4,7.4-9v-13.7h12.7v13.7c0,13.4-8.2,22.7-20.3,22.7
				C1245.6,516.2,1237.5,506.9,1237.5,493.4z"
            />
            <path id="XMLID_109_" d="M1286.2,419h41.3v13.7h-14.2v82.1h-12.9v-82.1h-14.2V419z" />
            <path id="XMLID_111_" d="M1337.4,419h12.9v95.8h-12.9V419z" />
            <path
              id="XMLID_113_"
              d="M1363.4,493v-52.3c0-13.8,8.3-23.1,20.8-23.1c12.4,0,20.7,9.3,20.7,23.1V493c0,13.8-8.3,23.1-20.7,23.1
				C1371.7,516.2,1363.4,506.9,1363.4,493z M1392,493v-52.3c0-6-3.1-9.4-7.8-9.4c-4.8,0-7.8,3.4-7.8,9.4V493c0,6,3.1,9.4,7.8,9.4
				C1388.9,502.5,1392,499.1,1392,493z"
            />
            <path id="XMLID_116_" d="M1418,419h14.3l16.5,64.3V419h12.7v95.8h-13.7l-17.1-61.3v61.3H1418V419z" />
            <path id="XMLID_118_" d="M1500,419h12.9v82.1h23.1v13.7h-36V419z" />
            <path id="XMLID_120_" d="M1534.1,419h41.3v13.7h-14.2v82.1h-12.9v-82.1h-14.2V419z" />
            <path
              id="XMLID_122_"
              d="M1585.3,419h20.3c12.3,0,20.3,9,20.3,22.9v50.1c0,13.8-7.9,22.9-20.3,22.9h-20.3V419z M1605,501.1
				c5,0,7.9-3.3,7.9-8.9v-50.6c0-5.6-2.9-8.9-7.9-8.9h-6.7v68.4H1605z"
            />
            <path id="XMLID_125_" d="M1639.6,500.4h12.9v14.4h-12.9V500.4z" />
          </g>
        </g>
      </g>
    </StyledLogo>
  )
}
