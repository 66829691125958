import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

export const Landing = ({ copy, data }) => {
  return (
    <StyledLanding data={data}>
      {copy}
      <StyledImg fluid={data} />
    </StyledLanding>
  )
}

const StyledLanding = styled.section`
  position: relative;
  grid-column: 1 / 4;
  width: 100%;
  height: ${({ data }) => (!data ? '100vh' : 'auto')};

  @media (max-width: 888px) {
    margin-top: 50px;
  }

  h1 {
    z-index: 9;
    position: absolute;
    padding: 1rem;
    color: var(--text);
    top: 25%;

    /* text-shadow: var(--text) 0px 0px 5px; */
  }
`

const StyledImg = styled(Img)`
  grid-column: 1 / 5;
  width: 100%;
  max-height: 100vh;
  filter: contrast(150%) opacity(65%);
  min-height: 900px;

  @media (max-width: 1080px) {
    min-height: 600px;
  }

  @media (max-width: 580px) {
    min-height: 300px;
    padding-top: 70px;
    filter: contrast(150%) opacity(55%);
  }
`
