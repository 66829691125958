import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

export const Navigation = () => {
  return (
    <StyledNav>
      <Link to="/">Home</Link>
      <Link to="/about/">About</Link>
      <Link to="/services/">Services</Link>
      <Link to="/contact/">Contact</Link>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: var(--text);
`
